import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Educating Participants`}</h1>
    <ul>
      <li parentName="ul">{`See: `}<a parentName="li" {...{
          "href": "/facilitation",
          "title": "facilitation"
        }}>{`facilitation`}</a>{``}</li>
      <li parentName="ul">{`See case study: "`}<strong parentName="li">{`Scoop News`}</strong>{`, Wellington, NZ"`}</li>
      <li parentName="ul">{`Scoop, as a news organization, is able to educate participants about the issue they are discussing by:`}
        <ul parentName="li">
          <li parentName="ul">{`referencing past articles`}</li>
          <li parentName="ul">{`offering links out to various credible sources of information`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      